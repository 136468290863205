import React, { useEffect, useMemo, useState } from 'react';
import { useDebounce } from './useDebounce';
import Input from '../../forms/Input';
import arrowDown from "../../assets/images/common/arrow-down.svg";
import search from "../../assets/images/common/search.svg";
import plus from "../../assets/images/common/plus-circle.png";
import edit from "../../assets/images/common/edit.svg";
import trash from "../../assets/images/common/trash.svg";
import Checkbox from '../../forms/Checkbox';
import ClickOutside from "react-click-outside";

const DropdownWithSearch = ({
  trigger,
  label,
  multiple,
  options,
  onAdd,
  onDelete,
  onEdit,
  onChange,
  defaultSelected,
  error
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);

  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  useEffect(() => {
    if (defaultSelected) {
      setSelectedItems(options.filter(el => defaultSelected.includes(el.value)));
    }
  }, [defaultSelected, options]);

  const filteredOptions = useMemo(() => options.filter(option =>
    option.label.toLowerCase().includes(debouncedSearchQuery.toLowerCase())
  ), [debouncedSearchQuery, options]);

  const toggleDropdown = () => {
    setIsOpen(prev => !prev);
  };

  const handleSelectItem = (item) => {
    if (multiple) {
      setSelectedItems((prevSelected) =>
        prevSelected.some((selectedItem) => selectedItem.value === item.value)
          ? prevSelected.filter((selectedItem) => selectedItem.value !== item.value)
          : [...prevSelected, item]
      );
    } else {
      setSelectedItems([item]);
      setIsOpen(false);
    }
    onChange(item)
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const isSelected = (item) => selectedItems.some(selectedItem => selectedItem.value === item.value);

  return (
    <ClickOutside style={{ width: '100%', margin: 0 }} onClickOutside={() => setIsOpen(false)}>
      <div style={{ width: '100%', position: 'relative', display: trigger ? 'flex' : 'block', justifyContent: 'flex-end', margin: 0 }}>
        {label && <p className="label" style={{ fontWeight: 700 }}>{label}</p>}
        {trigger?.(toggleDropdown) ?? (<div className="search-dropdown-toggle" onClick={toggleDropdown}>
          <p className='search-dropdown-toggle-text'>
          {selectedItems.length > 0
            ? selectedItems.map((item) => item.label).join(', ')
            : 'Select items...'}
          </p>
          <img src={arrowDown} className={`postfix ${isOpen ? 'active' : ''}`}/>
        </div>)}
        {isOpen && (
          <div className={`search-dropdown ${trigger ? 'with-trigger' : ''}`}>
            <Input
              prefix={<img src={search} />}
              value={searchQuery}
              onChange={handleSearchChange}    
              placeholder={'Search...'}
              isDropdownVisible={isOpen}
            />
            <div className={`search-dropdown-list ${onAdd && 'large'}`}>
              {filteredOptions.map((option, i) => (
                <div key={i} className="search-dropdown-item">
                  {multiple ? <Checkbox
                    value={isSelected(option)}
                    onChange={() => handleSelectItem(option)}
                    disabled={!multiple && selectedItems.includes(option)}
                    id={option.value}
                    name={option.label}
                  /> : <p onClick={() => handleSelectItem(option)}>{option.label}</p>}
                  <div className='buttons'>
                    {onEdit && !option.disabled &&  <button onClick={() => {
                      onEdit(option);
                    }}><img src={edit} alt="Edit" /></button>}
                    {onDelete && !option.disabled && <button onClick={() => {
                      onDelete(option)
                    }}><img src={trash} alt="Delete" /></button>}
                  </div>
                </div>
              ))}
            </div>
            {onAdd && <div className='add-new-btn-wrapper'>
              <button onClick={onAdd} className="add-new-btn"><img src={plus} /> Add New</button>
            </div>}
          </div>
        )}
        <span style={{ fontSize: '10px' }} className="error">{error}</span>
      </div>
    </ClickOutside>
  );
};

export default DropdownWithSearch;
