export const transformRecipeForState = recipe => ({
  ...recipe,
  type: recipe.type.map(t => t._id),
  season: recipe.season.map(s => s._id),
  allergens: recipe.allergens.map(a => a._id),
  isPublished: recipe.isPublished,
  calories: recipe.calories / recipe.servings,
  carbs: {
    grams: recipe.carbs.grams / recipe.servings,
    percent: recipe.carbs.percent
  },
  protein: {
    grams: recipe.protein.grams / recipe.servings,
    percent: recipe.protein.percent
  },
  fat: {
    grams: recipe.fat.grams / recipe.servings,
    percent: recipe.fat.percent
  },
  ingredients: recipe.ingredients.map(i => {
    if(i.heading) {
      return {
        name:i.name,
        heading:true
      }
    } else {
      return {
        name: i.name,
        quantity: i.quantity,
        form: i.form ? i.form: "",
        measure: i.measure,
        category: i.category?._id,
        displayUnit:i.displayUnit,
        displayAmount:i.displayAmount,
        shoppingList:i.shoppingList||{
          unit:"",
          amount:""
        },
        _id: i?._id
      };
    }
  })
});

export const validateIngredient = ingredient => {
  if(ingredient.heading) {
    return ingredient.name;
  }
  return (
    ingredient.name && ingredient.name.name ||
    ingredient.name && ingredient.name.fdcId ||
    ingredient.form ||
    ingredient.quantity ||
    ingredient.measure ||
    ingredient.category
  );
};
