const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || "staging";

const ENVIRONMENT_SETTINGS = {
  base_url: {
    // local: "https://master-expansion-rawk-admin-backend.k8.outsi.de",
    local: "http://localhost:8001",
    production: "https://data.rawktheyear.com",
    staging: "https://master-expansion-rawk-admin-backend.k8.outsi.de"
  },
  frontend_base_url: {
    local: "http://localhost:3000",
    production: "https://rawktheyear.com",
    staging: "http://localhost:3000"
  },
  subscription_types: {
    local: [
      { key: "5e5f675c1c9d44000086d9f3", value: "Monthly" },
      { key: "5e5f678f1c9d44000086d9f4", value: "Quarterly" },
      { key: "63ca919c2faa2c220fab0e19", value: "Annual" }
    ],
    production: [
      { key: "5ea1a46a4b23062d09154c46", value: "Monthly" },
      { key: "5eb5315a2181981704077c10", value: "Quarterly" },
      { key: "5ea1a4ad4b23062d09154c47", value: "Annual" }
    ],
    staging: [
      { key: "5e5f675c1c9d44000086d9f3", value: "Monthly" },
      { key: "5e5f678f1c9d44000086d9f4", value: "Quarterly" },
      { key: "63ca919c2faa2c220fab0e19", value: "Annual" }
    ]
  }
};

export const BASE_URL = ENVIRONMENT_SETTINGS.base_url[ENVIRONMENT];
export const FRONTEND_BASE_URL =
  ENVIRONMENT_SETTINGS.frontend_base_url[ENVIRONMENT];
export const SUBSCRIPTION_TYPES =
  ENVIRONMENT_SETTINGS.subscription_types[ENVIRONMENT];
