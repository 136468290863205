import { call } from "../core";

export const callGetAllergens = () => {
  return call("/enum/allergens", "GET", {});
};

export const callGetTypes = () => {
  return call("/enum/recipeTypes", "GET", {});
};

export const callGetSeasons = () => {
  return call("/enum/seasons", "GET", {});
};

export const callGetForms = () => {
  return call("/manage/recipeForms", "GET", {});
};

export const callGetUnits = () => {
  return call("/enum/units", "GET", {});
};

export const callGetCategories = () => {
  return call("/enum/categories", "GET", {});
};

export const callGetPermissions = () => {
  return call("/permissions", "GET", {});
};

export const callCreateIngredientForm = (name) => {
  return call(`/manage/recipeForms/`, 'POST', { body: { name }})
};

export const callEditIngredientForm = (id, name) => {
  return call(`/manage/recipeForms/${id}`, 'PUT', { body: { name }})
};

export const callDeleteIngredientForm = (id, replace) => {
  return call(`/manage/recipeForms/${id}`, 'DELETE', { params: { replace } })
};
