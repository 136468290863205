import React from "react";
import Modal from "../../../../components/Modal";

class ConfirmModal extends React.Component {
  render() {
    return (
      <Modal
        {...this.props}
        className="modal-flex-between"
        buttons={[
          {
            children: "Cancel",
            outlined: true,
            small: true,
            onClick: this.props.close,
          },
          {
            children: "Delete",
            small: true,
            onClick: (ids) => this.props.delete(this.props.ids),
          },
        ]}
      >
        <h6 className="page-section">Confirm</h6>
        <div style={{ marginBottom: 16 }}>
          {this.props.content
            ? this.props.content
            : `Are you sure you want to delete ${
                this.props.ids.length > 1 ? "these users" : "this user"
              }?`}
        </div>
      </Modal>
    );
  }
}

export default ConfirmModal;
